<template>
  <div :ref="ref" class="csn-game">
    <GameLists
      :getList="getFreePlayGameList"
      :itemsPerScreen="itemsPerScreen"
      :hasVericalUpperRow="true"
    />
  </div>
</template>

<script>
import { cardsPerScreenCalculationMixin } from '@/mixins'
import { GameApi } from '@/api'

export default {
  name: 'GameFreePlay',
  mixins: [cardsPerScreenCalculationMixin],
  components: {
    GameLists: () => import('@/components/GameLists'),
  },
  methods: {
    getFreePlayGameList: GameApi.getFreePlayGameList,
  },
}
</script>
